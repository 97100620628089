import React from "react"
import { Link } from 'gatsby';

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';

// Image imports
import trusts from '../images/trust-photo.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';

const Trusts = () => {
  return (
    <Layout>
        <SEO 
            title="Kansas City Attorney for Trusts | Mike Martin Law"
            description="Mike Martin’s extensive experience as a trust lawyer, along with his expertise in estate planning and probate, allows him to foresee and prevent potential problems with trusts."
        />
        <Card>
            <PageHeader 
                title="Trusts"
                image={trusts}
                alte="Writing a legal document"
            />
            <div className="grid col-span-1 sm:grid-cols-12 my-8">
                <div className="sm:col-span-8">
                    <p>Mike Martin Law can help you understand and establish the kind of trust that’s right for your situation. Unfortunately, trusts are often misused  – especially if the trust agreement isn’t carefully executed.</p>
                    <p>If you are looking for a trust attorney, Mike Martin Law can help insure that your trust will benefit those it is established to benefit.</p>
                    <p>Because trusts are private instruments and have no court oversight, it is essential that trust documents be written and executed properly.</p>
                    <p>Mike Martin’s extensive experience as a trust lawyer, along with his expertise in <Link to={ROUTES.ESTATES} className="text-secondary">estate planning</Link> and <Link to={ROUTES.PROBATE} className="text-secondary">probate</Link>, allows him to foresee and prevent potential problems with <Link to={ROUTES.TRUSTS} className="text-secondary">trusts</Link>.</p>
                    <h3 className="section-header">Why would I want a trust?</h3>
                    <p>There can be several advantages to establishing a trust, depending on your situation. Trusts can help your heirs avoid probate court and, in many instances, can help keep your affairs private.</p>
                    <p>Many trusts are not part of the probate court system and never become a matter of public record.</p>
                    <p>There are two basic types of trusts: testamentary trusts (which are created by a will) and inter vivos trusts (meaning “between living people”). Trusts can often make distributions to beneficiaries much faster than probate court.</p>
                    <p>In addition, administration of a trust is usually much less expensive than probate. A trust also can manage assets during a person’s lifetime, avoiding the need for a conservator.</p>
                    <ul>
                        <li><span className="font-bold">Testamentary Trusts</span> have no power or effect until the will of the donor is probated. Although a testamentary trust will not avoid the need for probate and will become a public document as part of your will, it can be useful in accomplishing many estate planning goals, especially as related to minor children.</li>
                        <li><span className="font-bold">Revocable Trusts</span> are often referred to as "living" trusts. With a revocable trust, the grantor maintains complete control over the trust and may amend, revoke or terminate the trust at any time. Revocable trusts are generally used for asset management, probate avoidance, and tax planning.</li>
                        <li><span className="font-bold">Supplemental Needs Trusts</span> enable the donor to provide for the continuing care of a disabled spouse, child, relative or friend while allowing the beneficiary to also receive public benefits.</li>
                        <li><span className="font-bold">Pet Trusts.</span> The Uniform Trust Code contains specific provisions allowing trusts to care for your pets after your death.</li>
                    </ul>
                    <p>In addition to creating a trust to avoid or simplify probate, you may also be able to utilize non-probate transfers as allowed by state law. Mike Martin Law can help you understand available methods of probate avoidance and how they may apply to your estate.</p>
                    <p className="font-bold">Do you need assistance establishing or amending a trust? Mike offers knowledge experience you can rely on. <Link to={ROUTES.CONTACT} className="text-secondary underline">Contact Mike today.</Link></p>
                </div>
            </div>
        </Card>
    </Layout>
  )
}

export default Trusts;